<template>

  <v-expansion-panels flat class='pb-4'>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate class="op-expansion-panel-icon">
        <template v-slot:actions>
          <v-icon color="black">
            mdi-cog
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row align="center"
        justify="center">
        <v-col cols="12" sm="10" lg="8">

          <v-divider></v-divider>

          <v-row justify="space-around" no-gutters>
            <v-col cols="12" sm="12">
              <v-checkbox
              color="black"
              v-model="doSemantic"
              label="Display Semantic Info"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-checkbox
              color="black"
              v-model="doScale"
              label="Scale Matters"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="9">
              <v-card-text class="py-0 text-center">
                Grid Width / Height (m)
              </v-card-text>
              <v-slider
              :disabled="!doScale"
              v-model="gridVal"
              :max="gridMax"
              :min="gridMin"
              hide-details
              class="align-center"
              color="black"
              track-color="gray"
              >
              <template v-slot:append>
                <v-text-field
                v-model="gridVal"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 50px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row
        no-gutters class='justify-center'
        >
        <v-switch dense color='black' hide-details
        v-model="tagsOn"
        label="Select All"
        ></v-switch>
      </v-row>

      <v-row no-gutters class='pb-4'>
        <v-col v-for="(tag,i) in pseudoClasslist"  :key="i" cols="4" xs="4" sm="3" md="2" class='op-checkbox-dense'>
          <v-checkbox
          dense
          v-model="tagsModel[i]"
          :label="tag"
          hide-details
          color="black"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-divider></v-divider>
    </v-col>

  </v-row>
</v-expansion-panel-content>
</v-expansion-panel>
</v-expansion-panels>
</template>


<script>

export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    polygonClassList() {
      //return this.$store.state.classList;
      return this.pseudoClasslist;
    },
  },
  data: function () {
    return {
      gridMin: 1,
      gridMax: 100,
      gridVal: 50,

      doScale: false,
      doSemantic: false,

      tags: this.polygonClassList,
      tagsOn: true,
      tagsModel: [],

      pseudoClasslist: [
        "building-outline",
        "balcony",
        "bath",
        "chimney",
        "closet",
        "corridor",
        "dining",
        "elevator",
        "foyer",
        "garden",
        "garage",
        "kitchen",
        "living",
        "room",
        "stair",
        "sleeping",
        "terrace",
        "wc",
      ],
    }
  },
  watch: {
    tagsOn(newValue){
      if(newValue==true){
        for(let i=0;i<this.tagsModel.length;i++){
          this.tagsModel[i] = true;
        }
      }else{
        for(let i=0;i<this.tagsModel.length;i++){
          this.tagsModel[i] = false;
        }
      }
    }
  },
  methods:{
  },
  mounted () {
    for(let i=0;i<this.pseudoClasslist.length;i++){
      this.tagsModel.push(true);
    }
  },
};
</script>
