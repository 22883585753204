<template>
  <div class="op-search-image">
  <div class="op-search-overlay op-cursor-pointer" v-on:click="redirectToProject(plan.project_id)">
    <span class="op-search-overlay-text">
           {{ plan.name}}
    </span>
  </div>
  <SVGThumbnail
    :points="getPoints(plan.points)"
    :name="plan.name"
    :project_id="plan.project_id"
    :imagePath="plan.thumbnail_path"
    :imageWidth="imageWidth"
    :imageHeight="imageHeight"
    :id="id"
    :selected_index="selected_index"
  >
  </SVGThumbnail>
  </div>
</template>

<script>
import Point from "@/geometry/Point.js";
import SVGThumbnail from "./SVGThumbnail.vue";

export default {
  props: {
    plan: {
      type: Object,
    },
    id: Number,
    selected_index: Number,
  },
  data: function () {
    return {
      imageWidth: 100,
      imageHeight: 100,
      img: null,
    };
  },
  components: {
    SVGThumbnail,
  },
  watch: {
    plan: function(){
      this.setImgDimensions();
    }
  },
  methods: {
    getPoints(coordinates) {
      let points = [];
      for (let arr of coordinates) {
        points.push(new Point(arr[0], arr[1]));
      }
      return points;
    },
    setImgDimensions(){
      this.imageWidth = this.plan.width_mm;
      this.imageHeight = this.plan.height_mm;
    },
    redirectToProject(project_id){
      this.$router.push({
        name: 'ProjectView',
        params: {
          projectid: project_id,
        }
      });
    },
  },
  created() {
    this.setImgDimensions();
  },
};
</script>
