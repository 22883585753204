<template>
  <v-card fluid flat>
    <v-toolbar flat dense class='op-breadcrumbs'>
    </v-toolbar>
  <ProjectsSearchBar/>
  <ProjectsTable v-on:reloadProjectsView="reloadProjectsView" :isLoggedIn="isLoggedIn" :userHasRole="userHasRole" :userHasId="userHasId"/>
</v-card>
</template>

<script>
import ProjectsTable from "@/components/ProjectsTable.vue";
import ProjectsSearchBar from "@/components/ProjectsSearchBar.vue";

export default {
  name: "ProjectsView",
  components: {
    ProjectsTable,
    ProjectsSearchBar,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Projects",
          disabled: false,
          to: {
            name: "ProjectsView",
          },
        },
      ],
    };
  },
  methods: {
    reloadProjectsView(router) {
      if(this.$route.query.type === 'myprojects') {
        this.$router.push({ path: '/projects', query: { type: 'browse' }}); // quick hack to reset userId before fetching account projects
        this.$nextTick(() => {
          this.$router.push(router);
        });
      } else {
        this.$router.go(); // otherwise just normal reload
      }
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
    userHasRole: function () {
      return this.$store.getters.userHasRole;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
    userHasId: function () {
      return this.$store.getters.userHasId;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
  },
}
</script>
