<template>
    <div>
      <div ref="map" class="map"></div>
      <div id="overlay" hide-overlay class="overlay"></div>
    </div>
  </template>
  
  <script>
  import 'leaflet/dist/leaflet.css';
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.js';
  import { WKT } from 'ol/format';
  
  export default {
    data() {
      return {
        points: [] // Initialize an empty array to store the points
      };
    },
    mounted() {
      const map = L.map(this.$refs.map).setView([0, 0], 2);
  
      L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
        {
          attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
          zIndex: 0
        }
      ).addTo(map);
  
      const overlay = L.popup({ closeButton: false, closeOnClick: true });
  
      map.on('click', (event) => {
        const latLng = event.latlng;
  
        map.eachLayer((layer) => {
          if (layer.feature && layer.feature.properties.project_id) {
            const name = layer.feature.properties.project_name;
            const coordinates = layer.getLatLng();
  
            if (latLng.distanceTo(coordinates) === 0) {
              overlay.setLatLng(coordinates);
              // Update the content with a clickable element
              const content = document.createElement('div');
              const text = document.createElement('span');
              text.textContent = name;
              text.style.cursor = 'pointer';
              text.style.color = 'blue'; 
              text.addEventListener('click', () => {
                this.handleClick(layer.feature.properties.project_id);
              });
              content.appendChild(text);
              overlay.setContent(content);
              //overlay.setContent(`${name}`);
              overlay.openOn(map);
            }
          }
        });
      });
  
      // Fetch the points from your API
      fetch('https://open-plans.herokuapp.com/project/fetch/points')
        .then((response) => response.json())
        .then((data) => {
          const features = [];
  
          data.projects.forEach((project) => {
            const wktParser = new WKT();
            const point = wktParser
              .readFeature(project.point)
              .getGeometry()
              .getCoordinates();
            //const point = transform([coords[0], coords[1]], 'EPSG:4326', 'EPSG:3857');
  
            const feature = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: point
              },
              properties: {
                project_id: project.project_id,
                project_name: project.project_name
              },
              id: project.project_id // Add an id property
            };
  
            features.push(feature);
          });
  
          const geoJsonLayer = L.geoJSON(features, {
            pointToLayer: (feature, latlng) => {
              return L.circleMarker(latlng, {
                radius: 4,
                fillColor: 'black',
                color: 'white',
                weight: 1,
                fillOpacity: 1
              });
            },
            onEachFeature: (feature, layer) => {
              layer.on('click', () => {
                const id = feature.properties.project_id;
                overlay.setLatLng(layer.getLatLng());
                overlay.setContent(`Project ID: ${id}`);
                overlay.openOn(map);
              });
            },
            zIndex: 1
          });
  
          geoJsonLayer.addTo(map);
        });
    },
    methods: {
      handleClick(projectId) {
        this.$router.push({
          name: 'ProjectView',
          params: {
            projectid: projectId
          }
        });
      }
    }
  };
  </script>
  
  <style>
  .container {
    position: relative;
    height: 100%;
  }
  
  .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .overlay {
    background-color: white;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid black;
  }
  </style>
  