export default class Point {

  constructor(x = 0, y= 0) {
    this.x = x;
    this.y = y;
  }

  static distance(x1, y1, x2, y2) {
    let dX = x2 - x1;
    let dY = y2 - y1;
    return Math.sqrt(dX * dX + dY * dY);
  }




}
