<template>

  <v-card
  height="100%"
  class="d-flex justify-space-around op-noselect"
  flat
  tile
  >

  <v-card flat>LEVEL <span class='font-weight-bold'> {{level}}</span> </v-card>
  <v-spacer></v-spacer>
  <v-card flat> NORTH
    <span class='pl-3 op-footer-compass-wrapper'>
      <v-icon id="op-footer-compass"> mdi-navigation</v-icon>
    </span>
  </v-card>

</v-card>

</template>

<script>

export default {
  data: function () {
    return {
    };
  },
  props: {
    level: Number,
    orientation: Number,
  },
  watch: {
    orientation: {
      deep: true,
      handler() {
        this.setCompassAngle(this.getOrientationDegrees());
      },
    },
  },
  methods:{
    setCompassAngle(angle){
      var div = document.getElementById('op-footer-compass');
      if(div){
        div.style.webkitTransform = 'rotate('+angle+'deg)';
        div.style.mozTransform    = 'rotate('+angle+'deg)';
        div.style.msTransform     = 'rotate('+angle+'deg)';
        div.style.oTransform      = 'rotate('+angle+'deg)';
        div.style.transform       = 'rotate('+angle+'deg)';
      }
    },
    getOrientationDegrees(){
      return parseInt(this.orientation * 180/Math.PI);
    }
  },
  computed: {
  },
  mounted () {
    this.setCompassAngle(this.getOrientationDegrees());
  },
};
</script>
