export default class SVGUtils {
    static getCoordinates(points) {
        let coords = "";
        for (let p of points) {
          coords += p.x + "," + p.y + " ";
        }
        return coords;
    }
    static getViewBox(width,height) {
        return "0 0 " + width + " " + height;
    }
    static getViewBoxFromRect(x1,y1,width,height) {
        return x1+" "+y1+" " + width + " " + height;
    }
    static getScale(scale){
        return "scale("+(scale)+")";
    }
}
