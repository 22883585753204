import axios from "axios";
import URI from "./uri";



export async function logout() {
    return new Promise((resolve) => {
        const url = `${URI}auth/logout`;
        axios.get(url, {withCredentials: true})
        .then((response) => {
            resolve(response.data);
        });
    });
}

export async function login(email, password) {
    return new Promise((resolve, reject) => {
        const url = `${URI}auth/login`;
        const data = {
            "email": email,
            "password": password
        }
        axios.post(url, data, {withCredentials: true})
        .then((response) => {
            console.log(response.data);
            resolve(response.data);
            
        })
        .catch((error) => {
            console.log(error);
            reject(error);
        });
    });
}


export async function signup(email, password, name) {
    return new Promise((resolve) => {
        const url = `${URI}auth/signup`;
        const data = {
            "email": email,
            "password": password,
            "name": name
        }
        axios.post(url, data, {withCredentials: true})
        .then((response) => {
            resolve(response.data);
        });
    });
}


export async function checkedLoginStatus() {
    return new Promise((resolve) => {
        const url = `${URI}auth/status`;
        axios.get(url, {
            withCredentials: true,
        })
        .then((response) => {
            resolve(response.data);
            console.log(response.data);
        });
    });
}
