<template>
  <v-card>
  <v-dialog v-model="dialogLogin" persistent max-width="600px" min-width="360px" @click:outside="dialogLogin = false">
      <v-card class="px-4">
        <v-card-title class="justify-center">
           Welcome to Open Plans
        </v-card-title>
        <v-card-text>
          <v-form ref="loginForm" v-model="validLogin" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="emailLogin"
                  :rules="emailRulesLogin"
                  label="E-mail"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="passwordLogin"
                  :append-icon="showPasswordLogin ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPasswordLogin ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPasswordLogin = !showPasswordLogin"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="op-card-actions" text @click="dialogLogin = false">
            Cancel
          </v-btn>
          <v-btn
            text
            :disabled="!validLogin"
            class="op-card-actions op-highlight"
            @click="tryToLogin"
          >
            Login
          </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-subtitle class="text-center">Not a member yet?
          <v-btn
          small
            text
            class="op-card-actions"
            @click="openDialogSignup"
          >
          Sign up
          </v-btn>
        </v-card-subtitle>
      </v-card>
  </v-dialog>
  <v-dialog v-model="dialogSignup" persistent max-width="600px" min-width="360px" @click:outside="dialogSignup = false">
    <v-alert v-if="signupNotification" :value="true" dismissible border="left" colored-border>
    {{ signupNotification }}
    </v-alert>
    
    <v-card class="px-4">
        <v-card-title class="justify-center">
           Create Account on Open Plans
        </v-card-title>
        <v-card-text>
          <v-form ref="registerForm" v-model="validSignup" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="usernameSignup"
                  :rules="[rules.required]"
                  label="Username"
                  maxlength="20"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="emailSignup"
                  :rules="emailRulesSignup"
                  label="E-mail"
                  :error-messages="emailError"
                  @click="resetErrorMessage"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="passwordSignup"
                  :append-icon="showPasswordSignup ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="showPasswordSignup ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPasswordSignup = !showPasswordSignup"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  block
                  v-model="verifySignup"
                  :append-icon="showPasswordSignup ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, passwordMatch]"
                  :type="showPasswordSignup ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirm Password"
                  counter
                  @click:append="showPasswordSignup = !showPasswordSignup"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="op-card-actions" text @click="dialogSignup = false">
            Cancel
          </v-btn>
          <v-btn
            class="op-card-actions op-highlight"
            text
            :disabled="!validSignup"
            @click="noSignUp"
            >Sign up
          </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
  </v-dialog>
  </v-card>
</template>

<script>

import { login } from "@/api/auth";
import { signup } from "@/api/auth";
import { checkedLoginStatus } from "../api/auth";

export default {
  props: {
    value: Boolean,
  },
  computed: {

    dialogLogin: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    passwordMatch() {
      return () => this.passwordSignup === this.verifySignup || "Password must match";
    },
  },

  methods: {
    openDialogSignup(){
      this.dialogSignup = true;
      this.dialogLogin = false;
    },
    noSignUp() {
      this.signupNotification = "Sorry, but we are currently not accepting new account registrations.";
    },
    async tryToLogin() {
      if (this.$refs.loginForm.validate()) {
        // submit form to server/API here...
        const response = await login(this.emailLogin, this.passwordLogin);
        if (response.succeeded) {
          this.$store.commit("login");
          console.log(this.$store.getters.isLoggedIn);
          this.$store.commit("loginstatus", await checkedLoginStatus(this.router));
          this.dialogLogin = false;
        }
      }
    },
    async tryToSignup() {
      if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...
        const response = await signup(this.emailSignup, this.passwordSignup, this.usernameSignup);
        if (response.succeeded) {
          this.$store.commit("login");
          console.log(this.$store.getters.isLoggedIn);
          this.$store.commit("loginstatus", await checkedLoginStatus(this.router));
          this.show = false;
          this.dialogSignup = false;
        } else {
          this.emailError = response.error;
        }
      }
    },
    resetErrorMessage() {
      this.emailError = [];
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  data: () => ({
    dialogSignup: false,
    validLogin: true,
    validSignup: true,
    showPasswordLogin: false,
    showPasswordSignup: false,
    signupNotification: null,

    verifySignup: "",

    usernameSignup: "",
    passwordSignup: "",
    emailSignup: "",
    emailRulesSignup: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    emailError: [],

    usernameLogin: "",
    passwordLogin: "",
    emailLogin: "",
    emailRulesLogin: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
    },
  }),
};
</script>
