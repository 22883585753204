import axios from "axios";
import URI from "./uri";

export async function fetchProject(projectId) {
  return new Promise((resolve) => {
    const url = `${URI}project/fetch/${projectId}`;
    axios.get(url).then((response) => {
      resolve(response.data.project);
    });
  });
}

export async function fetchRecentProjects(number, page) {
  return new Promise((resolve) => {
    const url = `${URI}project/fetch/recent?number=${number}&page=${page}`;
    axios.get(url).then((response) => {
      resolve(response.data.projects);
    });
  });
}

export async function fetchProjects(searchString, number, page) {
  return new Promise((resolve) => {
    const url = `${URI}project/fetch/${searchString}?number=${number}&page=${page}`;
    axios.get(url).then((response) => {
      resolve(response);
    });
  });
}

export async function saveProject(data) {
  return new Promise((resolve) => {
      const url = `${URI}project/save`;
      
      console.log(data);
      axios.post(url, data, {withCredentials: true})
      .then((response) => {
          resolve(response.data);
          console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  });
}

export async function deleteProject(data) {
  return new Promise((resolve, reject) => {
      const url = `${URI}project/delete`;
      axios.post(url, data, {withCredentials: true})
      .then((response) => {
          console.log(response.data);
          resolve(response.data);
      })
      .catch((error) => {
          console.log(error);
          reject(error);
      });
  });
}

export async function fetchProjectsAccount(accountId, number, page) {
  return new Promise((resolve) => {
    const url = `${URI}project/fetch/account/${accountId}?number=${number}&page=${page}`;
    axios.get(url).then((response) => {
      resolve(response.data.projects);
      console.log(response.data);
    });
  });
}

export async function countProjects() {
  return new Promise((resolve) => {
    const url = `${URI}project/count`;
    axios.get(url).then((response) => {
      resolve(response.data);
    });
  });
}

export async function filterFetchProjects(string, number, page) {
  return new Promise((resolve) => {
    const url = `${URI}project/fetch/filter?${string}&page=${page}&number=${number}`;
    axios.get(url).then((response) => {
      resolve(response.data.projects);
      console.log(response.data);
    });
  });
}