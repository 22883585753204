import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify' // path to vuetify export
import router from './router'
import store from './store'
import './styles/svg.scss'
import './styles/main.scss'

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

//import axios from 'axios'
//import VueAxios from 'vue-axios'
//import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
