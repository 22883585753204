import axios from "axios";
import URI from "./uri";

export async function fetchPlan(planId) {

  return new Promise((resolve) => {
    const url = `${URI}plan/fetch/${planId}`;
    axios.get(url).then((response) => {
      resolve(response.data.plan);
    });
  });

}


export async function deletePlan(data) {
  return new Promise((resolve, reject) => {
      const url = `${URI}plan/delete`;
      axios.post(url, data, {withCredentials: true})
      .then((response) => {
          console.log(response.data);
          resolve(response.data);
      })
      .catch((error) => {
          console.log(error);
          reject(error);
      });
  });
}