import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectsView from '../views/ProjectsView.vue'
import PlanView from '../views/PlanView.vue'
import GraphicalSearch from '../views/GraphicalSearch.vue'
import ProjectsMap from '../views/ProjectsMap.vue'
//import PolygonsView from '../views/PolygonsView.vue'
import About from '../views/About.vue'


Vue.use(VueRouter)

const routes = [
  /*
  {
    path: '/polygons',
    name: 'Polygons',
    component: PolygonsView,
    props: true,
  },
  */
  {
    path: '/search',
    name: 'GraphicalSearch',
    component: GraphicalSearch,
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    props: true,
  },
  {
    path: '/map',
    name: 'ProjectsMap',
    component: ProjectsMap,
    props: true,
  },
  {
    path: '/projects',
    name: 'ProjectsView',
    component: ProjectsView,
    props: (route) => ({ type: route.query.type }),
  },
  {
    path: '/project/:projectid',
    name: 'ProjectView',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "project" */ '../views/ProjectView.vue')
  },
  {
    path: '/newproject',
    name: 'NewProjectView',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "project" */ '../views/NewProjectView.vue')
  },
  {
    path: '/plan/:planid',
    name: 'PlanView',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "project" */ '../views/PlanView.vue')
    component: PlanView,
    beforeEnter: (to, from, next) => {
      if (to.params.planid) {
        next();
      } else {
      next(false);
      }
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
