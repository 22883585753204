import axios from "axios";
import URI from "./uri";

export async function fetchShape(data) {
    return new Promise((resolve) => {
      const url = `${URI}searchbyshape`;
      axios.post(url,data,{params: {number: 60}}).then((response) => {
        resolve(response.data);
      });
    });
  }
