import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    classList: ["outline", "balcony"],
    loggedInStatus: false,
    user: {
      role: '',
      account_id: null,
    },
  },
  mutations: {
    toggleDrawer: function (state) {
      return state.drawer = !state.drawer;
    },
    login(state) {
      state.loggedInStatus = true;
    },
    logout(state) {
      state.loggedInStatus = false;
      state.user.role = '';
      state.user.account_id = null;
    },
    loginstatus(state, status) {
      state.loggedInStatus = status.is_logged;
      state.user.role = status.role;
      state.user.account_id = status.id;
    },
  },
  actions: {
    toggleDrawer({ commit }) {
      commit('toggleDrawer');
    },

  },
  getters: {
    isLoggedIn: (state) => {
      return state.loggedInStatus;
    },
    userHasRole: (state) => {
      return state.user.role;
    },
    userHasId: (state) => {
      return state.user.account_id;
    },
    active: (state) => {
      return state.drawer;
    }
  }
})