<template>
  <v-container class='pa-0'>
    <v-row no-gutters align="center" justify="center">
      <v-col
        cols="10"
        sm="10"
        lg="6"
      >
        <v-card flat class="d-flex align-center justify-space-between">

          <v-text-field v-model="searchTerm" label="Search by keyword" @keydown.enter="search"></v-text-field>
          <v-btn x-large icon color="black" @click = "search">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

        </v-card>
      </v-col>
    </v-row>

    <PanelFilterOptions/>

  </v-container>
</template>


<script>

import PanelFilterOptions from "./PanelFilterOptions.vue";

export default {
    components: {
      PanelFilterOptions,
    },
  data: function () {
    return {
      searchTerm: null,
    };
  },
  props: {
  },
  watch: {
  },
  methods:{
    search(){
      if(this.searchTerm) {

        this.$router.push({ path: '/projects', query: { type: 'search='+this.searchTerm }})

      } else {

        this.$router.push({ path: '/projects', query: { type: 'browse' }})

      }
    }
  },
  computed: {
  },
  mounted () {
  },
};
</script>
