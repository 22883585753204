<template>
  <v-card  fluid flat>

    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
      height="3"
    ></v-progress-linear>

    <v-row no-gutters class="op-search-toolbar" justify="center" align="center">
      <v-btn small v-if="selectedResult" fab text v-on:click="clear">
        <v-icon>
          mdi-delete-forever
        </v-icon>
      </v-btn>
    </v-row >

    <v-row no-gutters justify="center" align="center" class='mt-0'>

      <v-col cols="12" sm="1" class="justify-center text-center d-none d-sm-flex">
        <v-btn v-if="selectedResult" fab text x-large @click="prev" >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" sm="10" class="justify-center">
        <v-card
        tile
        flat
        fluid
        pa-0
        class="fill-height text-center"
        >
        <SVGSearch
        v-model="points"
        :selectedResult="selectedResult"
        @polygonClosed="search"
        @polygonChanged="search"
        @clear="restart"
        v-touch="{
          left: () => next(),
          right: () => prev(),
          }"
          />
        </v-card>
      </v-col>

      <v-col cols="12" sm="1" class="justify-center text-center d-none d-sm-flex">
        <v-btn  v-if="selectedResult" fab text x-large @click="next">
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <PanelGraphicalOptions v-if="selectedResult && false"/>
    <v-row no-gutters>
      <v-col
      class='pa-2'
      cols="12"
      sm="4"
      md="2"
      v-for="(item, index) in results"
      :key="index"
      >
      <ProjectCardSearchResult  :plan="item" :id="index" :selected_index="selected"/>
    </v-col>
  </v-row>


</v-card>
</template>

<script>
import Point from "@/geometry/Point.js";
import SVGSearch from "@/components/SVGSearch.vue";
import ProjectCardSearchResult from "@/components/ProjectCardSearchResult.vue";
import PanelGraphicalOptions from "@/components/PanelGraphicalOptions.vue";
import { fetchShape } from "@/api/shape";

export default {
  name: "GraphicalSearch",
  components: {
    SVGSearch,
    ProjectCardSearchResult,
    PanelGraphicalOptions,
  },
  data() {
    return {
      searchString: "recent",
      results: [],
      selected: 0,
      points: [],
      showDialogGraphicalOptions: false,
      loading: false,
    };
  },
  computed: {
    selectedResult() {
      return this.results[this.selected];
    },
  },
  methods: {
    next() {
      this.selected = (this.selected + 1) % this.results.length;
    },
    prev() {
      this.selected = this.selected - 1;
      if (this.selected < 0) {
        this.selected = this.results.length - 1;
      }
    },
    getPoints(coordinates) {
      let points = [];
      for (let arr of coordinates) {
        points.push(new Point(arr[0], arr[1]));
      }
      return points;
    },
    restart() {
      this.results = [];
      this.selected = 0;
    },
    clear() {
      this.points = [];
      this.results = [];
      this.selected = 0;
    },
    search() {
      if (this.points.length > 0) {
        let search = { polygon: this.points };
        this.fetchShapes(search);
      }
    },

    async fetchShapes(data) {
      this.loading = true;
      this.selected = 0;
      const response = await fetchShape(data);
      this.loading = false,
      this.results = response.similar_plan_list;
    },
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode == 39) {
        this.next();
      }
      else if (e.keyCode == 37) {
        this.prev();
      }
    });
  },
};

</script>
