<template>
  <svg
  v-if="imagePath != null"
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  :viewBox="getViewBox"
  xml:space="preserve"
  preserveAspectRatio="xMidYMid meet"
  width="60%"
  height="60%"
  >

<image
:href="imagePath"
:height="imageHeight"
:width="imageWidth"
preserveAspectRatio="xMinYMin meet"/>

<polygon v-if="points.length > 2" v-bind:class="getClass"
  :points="getCoordinates"
  />
  <line v-if="points.length==2" v-bind:class="getClass"
  :x1="points[0].x" :y1="points[0].y" :x2="points[1].x" :y2="points[1].y"
  />
</svg>
</template>

<script>
import SVGUtils from "@/geometry/SVGUtils.js";
import Matrix from "@/geometry/Matrix.js";

export default {
  props: {
    imagePath: String,
    img: null,
    name: String,
    project_id: Number,
    points: Array,
    id: Number,
    selected_index: Number,
    imageWidth: Number,
    imageHeight: Number,
  },

  data: function () {
    return {
      svgElement: null,
      scale: 1,
      ctm: null,
    };
  },
  computed: {
    getCoordinates : function() {
      let coords = "";
      for (let p of this.points) {
        let x = p.x;
        let y = p.y;
        coords += x + "," + y + " ";
      }
      return coords;
    },
    getViewBox: function () {
      return SVGUtils.getViewBox(
        this.imageWidth,
        this.imageHeight
      );
    },
    getClass: function(){
      if(this.id==this.selected_index){
        return 'op-svg-thumb-selected';
      }else{
        return 'op-svg-thumb';
      }
    },
  },
  methods: {
    updateScale() {
      this.ctm = this.svgElement.getScreenCTM().inverse();
      this.scale = Matrix.decomposeMatrixScale(this.ctm);
    },
  },
  created() {
  },
};
</script>
