<template>
  <v-card flat class="op-editor-wrapper">
    <v-card flat class="op-editor-toprow">

      <v-toolbar flat dense class='op-breadcrumbs op-noselect'>
        <v-breadcrumbs :items="breadcrumbs" divider="/" ></v-breadcrumbs>
      </v-toolbar>

      <v-row no-gutters v-if="!editing && userEditRights && !$isMobile()">
        <v-col cols="12" sm="12" md="12">
          <v-toolbar  class="op-editor-toolbar" flat dense>
            <v-spacer></v-spacer>
            <v-btn icon color="black" v-on:click="editingEmit">
              <v-icon> mdi-square-edit-outline</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="editing && userEditRights">
        <v-col cols="12" sm="12" md="12">
          <v-toolbar  class="op-editor-toolbar" flat dense>

            <v-spacer></v-spacer>

            <v-btn-toggle
            v-model="toolmodus"
            v-on:change="onChangedModus"
            group
            >
            <v-btn :value="0"  icon color="black">
              <v-icon>mdi-cursor-default-outline</v-icon>
            </v-btn>
            <v-btn :value="1"  icon color="black">
              <v-icon>mdi-vector-polyline-plus</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-divider vertical ></v-divider>

          <v-btn :value="3" icon color="black" v-on:click="openDialogLevel()">
            <v-icon>mdi-layers-triple-outline</v-icon>
          </v-btn>
          <v-btn :value="1" icon color="black" v-on:click="openDialogScale()">
            <v-icon> mdi-ruler</v-icon>
          </v-btn>
          <v-btn :value="1" icon color="black" v-on:click="openDialogOrient()">
            <v-icon> mdi-compass-outline</v-icon>
          </v-btn>

          <v-divider vertical></v-divider>

          <v-btn  icon color="black"  v-on:click="openDialogSave()">
            <v-icon> mdi-content-save</v-icon>
          </v-btn>
          <v-btn  icon color="black"  v-on:click="openDialogExit()">
            <v-icon> mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

  </v-card> <!-- END op-editor-toprow -->

  <v-card flat class="op-editor-main">

    <v-card flat class="op-editor-polygons-column">

      <PolygonsOfPlanTable
      v-model="polygons"
      class="align-end flex-column flex-grow-1"
      v-on:arraySelectIndex="arraySelectIndexFromTable"
      v-on:deleteIndex="removeFromPolygons"
      :toolmodus="toolmodus"
      :editing="editing"
      :isLoggedIn="isLoggedIn"
      :arraySelectIndex="arraySelectIndex"
      @input="updateModel"
      @togglePolygonsVisibility="togglePolygonsVisibility"
      />

    </v-card>

    <v-card flat class="op-editor-canvas">

      <span class="op-editor-canvas-svg">

        <SVGEditor
        v-model="polygons"
        ref="svgEditorChild"
        v-on:arraySelectIndex="arraySelectIndexFromSVG"
        v-on:reopenDialogScale="reopenDialogScale"
        v-on:scaleAllByFactor="scaleAllByFactor"
        :imageURL="plan.image_path"
        :imageWidth="plan.width_mm"
        :imageHeight="plan.height_mm"
        :toolmodus="toolmodus"
        :editing="editing"
        :isLoggedIn="isLoggedIn"
        :scaleLineIsVisible="scaleLineIsVisible()"
        @addToPolygons="addToPolygons"
        :arraySelectIndex="arraySelectIndex"
        :textFieldScale="textFieldScale"
        :numberOfTableElements="numberOfTableElements"
        />

      </span>

      <span class="op-editor-canvas-footer d-none d-sm-block">
        <PlanFooter
        :level="plan.floor"
        :orientation="plan.orientation"
        />
      </span>

    </v-card>

  </v-card><!-- END op-editor-main -->

  <!--SAVE/EXIT DIALOGS -->
  <v-dialog hide-overlay v-model="dialogSave" max-width="500px">
    <v-card>
      <v-card-title class="justify-center">Save your changes to this plan?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" text @click="cancelSave">Cancel</v-btn>
        <v-btn class="op-card-actions op-highlight" text @click="commitSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogExit" max-width="500px">
    <v-card>
      <v-card-title class='justify-center'>Save your changes before exiting?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" color="red" text @click="discardAndReturnToProject">Discard changes</v-btn>
        <v-btn class="op-card-actions" text @click="cancelExit">Cancel</v-btn>
        <v-btn class="op-card-actions op-highlight" text @click="commitSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!--LEVEL DIALOG -->
  <v-dialog persistent hide-overlay v-model="dialogLevel" max-width="500px">
    <v-card>
      <v-card-title class="justify-center text-center">Select Plan Level</v-card-title>
      <v-card-subtitle>
        <v-text-field
        label="Level"
        type="number"
        v-model="modelLevel"
        :value="plan.floor"
        :rules="[rules.integerRule]"
        ></v-text-field>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" text @click="cancelLevel">Cancel</v-btn>
        <v-btn class="op-card-actions op-highlight" text @click="saveLevel">Preview</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!--SCALE DIALOG -->
  <v-dialog persistent hide-overlay v-model="dialogScale" max-width="500px">
    <v-card >
      <v-card-title class="justify-center text-center">Draw a line on the Plan and set its Length to determine the Plan Scale.</v-card-title>
      <v-card-subtitle class="justify-center op-vcard-btn">
        <v-btn color="black" class='white--text' @click="newScale">NEW LINE</v-btn>
        <v-text-field type="number" :rules="[rules.required]" v-model="textFieldScale" label="Drawn line Length in mm"></v-text-field>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" text @click="cancelScale">Cancel</v-btn>
        <v-btn class="op-card-actions op-highlight" text @click="saveScale">Preview</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!--ORIENT DIALOG -->
  <v-dialog persistent hide-overlay v-model="dialogOrient" max-width="500px">

    <v-card>
      <v-card-title class="justify-center text-center">Rotate the compass to point North or set degrees.</v-card-title>

      <v-card-subtitle class="justify-center op-vcard-btn">
        <v-btn fab x-large id="op-vcard-btn-compass" @click="rotateCompass">
          <v-icon> mdi-navigation mdi-rotate-90</v-icon>
        </v-btn>
        <div class="op-vcard-floater">
          <v-text-field type="number" v-model="modelCompass" :rules="degreesRules" id="op-vcard-field-degrees"
          label="Degrees"></v-text-field>
          <v-btn text class="mt-5 ml-4" @click="setCompassByText"
          >Set</v-btn>
        </div>

      </v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="op-card-actions" text @click="cancelOrient">Cancel</v-btn>
        <v-btn class="op-card-actions op-highlight" text @click="saveOrient">Preview</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</v-card>
</template>

<script>
import PolygonsOfPlanTable from "./PolygonsOfPlanTable.vue";
import SVGEditor from "./SVGEditor.vue";
import PlanFooter from "./PlanFooter.vue";
import Polygon from "@/geometry/Polygon.js";
import { fetchProject } from "@/api/project";
import { saveProject } from "@/api/project";

export default {
  name: "PlanCard",
  props: {
    plan: {
      type: Object,
    },
    editing: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    userHasRole: {
      type: String,
      default: '',
    },
    userEditRights: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      img: null,
      toolmodus: 0, //0=select/edit, 1=draw, -1=scale
      selectedClass: 0,

      dialogSave: false,
      dialogExit: false,

      dialogOrient: false,
      dialogLevel: false,
      dialogScale: false,

      polygons: null,

      arraySelectIndex: null,
      numberOfTableElements: 0,
      deletePolygonIds: [],

      modelLevel: null,
      modelCompass: null,
      textFieldScale: null,
      textFieldScaleTemp: null,

      degreesRules: [
        (v) =>
        (v >= 0 && v < 360) ||
        "Degrees must be set between 0 and 359.",
      ],
      rules: {
        required: v => {
          if (!isNaN(parseFloat(v)) && v > 0) return true;
          return 'Length has to be greater than zero.';
        },
        integerRule: v => {
          if (Number.isInteger(parseFloat(v))) return true; 
          return 'Round numbers only'
        },
      },
    };
  },

  components: {
    PolygonsOfPlanTable,
    SVGEditor,
    PlanFooter,
  },

  methods: {
    onDelete() {},
    updateModel(newModel){
      this.polygons = newModel;
    },
    togglePolygonsVisibility(){
      let count = 0;
      for(let i=0;i<this.polygons.length;i++){
        if(this.polygons[i].visible){
          count++;
        }
      }
      if(count==this.polygons.length){
        for(let j=0;j<this.polygons.length;j++){
          this.polygons[j].visible = false;
        }
      }else{
        for(let j=0;j<this.polygons.length;j++){
          this.polygons[j].visible = true;
        }
      }
    },
    scaleLineIsVisible(){
      if(this.toolmodus==-1 || this.dialogScale == true){
        return true;
      }return false;
    },
    addToPolygons(newPolygon){
      console.log("adding " + newPolygon);
      this.polygons.push(newPolygon);
      this.numberOfTableElements+=1;
    },

    onChangedModus() {
      this.clearPoints();
    },

    clearPoints(){
      this.$refs.svgEditorChild.clearPointsIfOpen();
    },
    deselectAll(){
      this.arraySelectIndex = null;
    },
    commitSave(){
      this.dialogSave = false;
      this.prepareVueObjectAndSave();
      //this.$emit('reloadPlanView'); /* after pushing changes to DB, reload plan so that first-last point are not duplicate (avoid scaling issue) */
    },
    async prepareVueObjectAndSave(){
      /* reclose polygons that were closed */
      for(let i=0;i<this.polygons.length;i++){
        if(this.polygons[i].pointsWereClosed){
          console.log("points were closed... reclosing");
          this.polygons[i].points.push(this.polygons[i].points[0]);
        }
        this.polygons[i].plan_id = this.plan.id;
      }
      /* Save to the DB only properties that existed before (e.g. floor, width_mm, height_mm, orientation) */
      /* Skip properties that were created in the PlanEditor (e.g. In polygons array: visible, disabled, arrayIndex, tableIndex, pointsWereClosed) */
      /* Generate New Properties if not exists (e.g. In polygons array: id) */
      if(this.deletePolygonIds.length) {
        this.plan.deleted_polygon_ids = this.deletePolygonIds;
        console.log(this.plan.deleted_polygon_ids);
      }
      /* prepare plan data with project data */
      let project = await fetchProject(this.plan.project_id);
      project.plans = [this.plan];
      let output = JSON.stringify(project);
      console.log(output);
      this.tryToSavePlan(project);
    },
    async tryToSavePlan(data) {
      //if (this.$refs.registerForm.validate()) {
        // submit form to server/API here...
        const response = await saveProject(data);
        if (response.succeeded) {
          //this.$store.commit("login");
          this.$emit('reloadPlanView'); /* after pushing changes to DB, reload plan so that first-last point are not duplicate (avoid scaling issue) */
       // }
      }
    },
    // saveAndExit(){
    //   this.prepareVueObjectForSaving();
    //   this.dialogExit = false;
    //   this.$emit('returnToProject');
    // },
    cancelSave() {
      this.dialogSave = false;
    },
    saveScale(){
      this.$refs.svgEditorChild.saveScale();
      this.textFieldScaleTemp = this.textFieldScale;
      this.dialogScale = false;
    },
    cancelScale() {
      this.$refs.svgEditorChild.revertScale();
      this.textFieldScale = this.textFieldScaleTemp;
      this.dialogScale = false;
    },
    newScale(){
      this.$refs.svgEditorChild.newScale();
      this.toolmodus = -1;
      this.dialogScale = false;
    },
    saveOrient(){
      if(!this.modelCompass) {
        this.plan.orientation = null;
      } else {
        this.plan.orientation = this.modelCompass * Math.PI/180;
      }
      this.dialogOrient = false;
    },
    cancelOrient() {
      this.modelCompass = parseInt(this.plan.orientation * 180/Math.PI);
      this.setCompassAngle(this.getSetToBtnDegrees(this.modelCompass));
      this.dialogOrient = false;
    },
    openDialogOrient(){
      this.deselectAll();
      this.clearPoints();
      this.dialogOrient = true;
      this.$nextTick(() => {
        this.modelCompass = parseInt(this.plan.orientation * 180/Math.PI);
        this.setCompassAngle(this.getSetToBtnDegrees(this.modelCompass));
      });
    },
    openDialogLevel(){
      this.deselectAll();
      this.clearPoints();
      this.dialogLevel = true;
      this.$nextTick(() => {
        this.modelLevel = this.plan.floor;
      });
    },
    openDialogScale(){
      this.deselectAll();
      this.clearPoints();
      this.dialogScale = true;
    },
    openDialogSave(){
      this.deselectAll();
      this.clearPoints();
      this.dialogSave = true;
    },
    openDialogExit(){
      this.deselectAll();
      this.clearPoints();
      this.dialogExit = true;
    },
    discardAndReturnToProject() {
      this.deletePolygonIds = [];
      this.dialogExit = false;
      this.$emit('returnToProject');
    },
    cancelExit(){
      this.dialogExit = false;
    },
    cancelLevel() {
      this.modelLevel = this.plan.floor;
      this.dialogLevel = false;
    },
    saveLevel(){
      this.plan.floor = parseInt(this.modelLevel);
      this.dialogLevel = false;
    },
    getCompassCenter(event){
      let domRect = event.target.getBoundingClientRect();
      let c = [(domRect.left + domRect.right)/2.0, (domRect.top + domRect.bottom)/2.0];
      return c;
    },
    rotateCompass(event){
      let mouseX = event.clientX;
      let mouseY = event.clientY;
      let c = this.getCompassCenter(event);
      let angleDeg = Math.atan2(mouseY - c[1], mouseX - c[0]) * 180 / Math.PI;
      this.setCompassAngle(parseInt(angleDeg));
      this.modelCompass = parseInt(this.getBtnToSetDegrees(angleDeg));
    },
    setCompassByText(){
      let deg = this.modelCompass;
      this.setCompassAngle(this.getSetToBtnDegrees(deg));
    },
    setCompassAngle(angle){
      let div = document.getElementById('op-vcard-btn-compass');
      if(div){
        div.style.webkitTransform = 'rotate('+angle+'deg)';
        div.style.mozTransform    = 'rotate('+angle+'deg)';
        div.style.msTransform     = 'rotate('+angle+'deg)';
        div.style.oTransform      = 'rotate('+angle+'deg)';
        div.style.transform       = 'rotate('+angle+'deg)';
      }
    },
    arraySelectIndexFromTable(arrayIndex){
      this.arraySelectIndex = arrayIndex;
      this.polygons[arrayIndex].visible = true;
    },
    arraySelectIndexFromSVG(index){
      this.arraySelectIndex = index;
    },
    removeFromPolygons(index){
      let id = this.polygons[index].id;
      this.polygons.splice(index, 1);
      this.numberOfTableElements-=1;
      for(let i=index;i<this.polygons.length;i++){
        this.polygons[i].tableIndex-=1;
        this.polygons[i].arrayIndex-=1;
      }
      this.arraySelectIndex = -1;
      if (Number.isInteger(id)) {
        this.deletePolygonIds.push(id);
      }
    },
    reopenDialogScale(newTextFieldValue){
      this.textFieldScale = newTextFieldValue;
      this.toolmodus = 0;
      this.openDialogScale();
    },
    getCleanPolygons(polygons){
      let cleanPolygons = [];
      for(let i=0;i<polygons.length;i++){
        let poly = polygons[i];
        if (!Array.isArray(poly.tags)) {
          poly.tags = []; /* set empty list of tags if not existing yet */
        } 
        let tagStr = poly.tags.toString();
        if(tagStr == "wall" || tagStr == "door" || tagStr.startsWith("step-")){
          this.$set(poly, 'disabled', true); /* DISABLED property, to disable certain polygons from the viewer (SVG & Polygon Table)*/
        }else{
          this.$set(poly, 'visible', true); // UI Visibility (eye-on eye-off)
          this.$set(poly, 'tableIndex', this.numberOfTableElements); // TABLEINDEX property, saves table row index (for UI highlight)
          this.numberOfTableElements++;
        }
        cleanPolygons.push(poly);
        this.$set(poly, 'arrayIndex', i); // ARRAYINDEX property, array index in the polygons array
      }
      return cleanPolygons;
    },
    getOpenPolygons(polygons){
      /* adds property pointsWereClosed = true to closed polygons and removes their last point Object */
      /* in order to properly display them (svg polygon convention) and scale them (avoid duplicate object). */
      /* the point will be added back when saving */
      for(let i=0;i<polygons.length;i++){
        let poly = polygons[i];
        if(Polygon.isClosed(poly.points)){
          this.$set(poly, 'pointsWereClosed', true);
          poly.points.splice(-1);
        } else{
          this.$set(poly, 'pointsWereClosed', false);
        }
      }
      return polygons;
    },
    scaleAllByFactor(factor){
      console.log("scaling all by factor " + factor);
      /* To change the universal plan scale the properties 'width_mm', 'height_mm' must be updated */
      /* As well as all the x,y (mm) coordinates of the polygons (including the 'disabled' polygons, e.g. lines) */

      this.plan.width_mm *= factor;
      this.plan.height_mm *= factor;
      for(let i=0;i<this.polygons.length;i++){
        for(let j=0;j<this.polygons[i].points.length;j++){
          this.polygons[i].points[j].x *= factor;
          this.polygons[i].points[j].y *= factor;
        }
      }
    },
    getBtnToSetDegrees(deg){
      if(deg<-90){
        return deg+450;
      }else{
        return deg+90;}
      },
      getSetToBtnDegrees(deg){
        return deg-90;
      },
      editingEmit(){
        this.$emit('editPlan');
      },
    },
    watch: {
      plan: {
        deep: true,
        handler(){
          //console.log("plan has changed");
          //this.$emit("updatePlan", this.plan);
        },
      },
      polygons: {
        deep: true,
        handler() {
        },
      },
    },
    computed: {
      classList() {
        return this.$store.state.classList;
      },
      indexedItems() {
        return this.polygons.map((item, index) => ({
          pid: index,
          ...item,
        }));
      },
      breadcrumbs() {
        return [
          {
            text: "Projects",
            disabled: false,
            to: {
              name: "ProjectsView",
              query: { type: "browse" },
            },
          },
          {
            text: "Project " + this.plan.project_id,
            disabled: false,
            to: {
              name: "ProjectView",
              params: { projectid: this.plan.project_id },
            },
          },
          {
            text: "Plan " + this.plan.id,
            disabled: true,
            to: {
              name: "PlanView",
              params: { planid: this.plan.id },
            },
          },
        ];
      },
    },

    created() {
      if (this.plan.polygons != null) {
        this.plan.polygons = this.getCleanPolygons(this.plan.polygons);
        this.plan.polygons = this.getOpenPolygons(this.plan.polygons);
        this.polygons = this.plan.polygons;
        /*
        for(let i=0;i<this.plan.polygons.length;i++){
        for (const property in this.plan.polygons[i]) {
        console.log(`${property}: ${this.plan.polygons[i][property]}`);
      }
    }
    */
  }
},
};
</script>
