<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped right temporary class="custom-drawer">
      <v-list nav dense flat>
        <v-list-item-group v-model="group" class="op-navlist">
          <div v-for="(item,i) in navItems" :key="item.title">
            <v-list-item v-if="i<4" :to="item.link">
              <v-list-item-icon>
                <v-icon v-if="i<4" v-text="item.icon" color="black"></v-icon>
                <!--<v-icon v-if="i>=2" v-text="item.icon" color="grey"></v-icon>--> <!--remove these options later -->
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="i<4" v-text="item.title" color="black"></v-list-item-title>
                <!--<v-list-item-title v-if="i>=2" v-text="item.title" class="grey--text"></v-list-item-title>--> <!--remove these options later -->
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="i==3" class="my-3"></v-divider>

            <v-list-item v-if="!isLoggedIn && i==4 && !$isMobile()" @click="showLoginDialog = true">
              <v-list-item-icon>
                <v-icon v-text="item.icon" color="black"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isLoggedIn && i==5 && !$isMobile()" :to="item.link">
              <v-list-item-icon>
                <v-icon v-text="item.icon" color="primary"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isLoggedIn && i==6 && !$isMobile()" @click="newProjectRoute">
              <v-list-item-icon>
                <v-icon v-text="item.icon" color="primary"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="isLoggedIn && i==7 && !$isMobile()" @click="logoutUser">
              <v-list-item-icon>
                <v-icon v-text="item.icon" color="primary"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" flat dense app>
      <v-toolbar-title>
        <router-link to="/" :key="$route.fullPath" v-if="!homePage()" class="op-noselect">Open Plans</router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="logo-container">

        <img src="@/assets/eth.png" alt="eth" class="logo-image" />

      <a href="https://dbt.arch.ethz.ch/">
        <img src="@/assets/dbt.png" alt="dbt" class="logo-image" />
      </a>
    </div>

      <v-app-bar-nav-icon color="black" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <v-container fluid class="op-homepage-titles op-fade-in" v-if="homePage()"> <!--fill-height optional-->
        <v-row> <!--<align="center" justify="center">-->
            <v-col cols="12" class="op-homepage-titles-main">Open Plans</v-col>
            <v-col cols="12">
            <router-link to="/search" :key="$route.fullPath">Search Plans by Drawing</router-link><br>
            </v-col>
            <v-col cols="12">
            <router-link to="/projects?type=browse" :key="$route.fullPath">Browse Projects</router-link>
            </v-col>
        </v-row>
      </v-container>
      <DialogLoginSignup v-model="showLoginDialog"></DialogLoginSignup>
      <router-view :key="$route.fullPath" />
    </v-main>
    <!--
    <v-footer app>
  </v-footer>
-->
</v-app>

</template>

<style>
  .logo-container {
  display: flex;
  align-items: right;
  margin-right: 0px; 
  margin-top: 6px;
}

.logo-image {
  width: 120px; /* Adjust the width as needed */
  height: 44px; /* Maintain aspect ratio */
}
</style>

<script>
import DialogLoginSignup from "./components/DialogLoginSignup";


import { checkedLoginStatus } from "@/api/auth";
import { logout } from "@/api/auth";

export default {
  components: {
    DialogLoginSignup,
  },
  created() {
    this.checkLoginStatus();
  },
  data: () => ({
    pseudoLogin: false,
    drawer: false,
    group: null,
    showLoginDialog: false,
    showNewProjectDialog: false,

    navItems: [
      { icon: 'mdi-information',
        title: 'About Open Plans',
        link: "/about",
      },
      {
        icon: 'mdi-draw',
        title: 'Search Plans by Drawing',
        link: "/search",
      },
      {
        icon: 'mdi-folder-search',
        title: 'Browse Projects',
        link: "/projects?type=browse",
      },
      {
        icon: 'mdi-map',
        title: 'Projects Map',
        link: "/map",
      },
      {
        icon: 'mdi-account-circle',
        title: 'User Login',
        link: "",
      },
      {
        icon: 'mdi-folder-account',
        title: 'My Projects',
        link: "/projects?type=myprojects",
      },
      {
        icon: 'mdi-plus-circle',
        title: "Create New Project",
        link:"",
      },
      {
        icon: 'mdi-logout-variant',
        title: 'Logout',
        link: "",
      },
    ],
  }),
  
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
      /* Temporary Variable pseudoLogin for shortcut login */
      //return this.pseudoLogin;
    },
  },

  methods: {
    async checkLoginStatus() {
      this.$store.commit("loginstatus", await checkedLoginStatus(this.router));
    },
    async logoutUser() {
      const response = await logout();
      if (response.succeeded) {
        this.$store.commit("logout");
      }
    },
    homePage() {
      if(this.$route.path == "/" || this.$route.path == "/home" ) {
        return true
      } else {
        return false
      }
    },
    newProjectRoute() {
        this.$router.push({
          name: 'NewProjectView',
        });
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
