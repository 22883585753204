<template>
  <svg
  class="op-search-canvas"
  width="501px"
  height="501px"
  xmlns="http://www.w3.org/2000/svg"
  viewbox="0 0 500 500"
  @mousedown="onMouseDown"
  @mousemove="onMouseMove"
  @mouseup="onMouseUp"
  >
  <defs>

    <marker id="dot"
    viewBox="0 0 10 10" refX="5" refY="5"
    markerWidth="5" markerHeight="5">
    <circle class="op-svg-search" cx="5" cy="5" r="4" stroke="black" stroke-width="2" fill="white" />
  </marker>

  <pattern
  id="smallGrid"
  width="10"
  height="10"
  patternUnits="userSpaceOnUse"
  >
  <path
  d="M 10 0 L 0 0 0 10"
  fill="none"
  stroke="black"
  opacity="0.25"
  stroke-width="1"
  stroke-dasharray="1 1"
  ></path>
</pattern>
<pattern
id="grid10"
width="100"
height="100"
patternUnits="userSpaceOnUse"
>
<rect width="100" height="100" fill="url(#smallGrid)"></rect>
<path
d="M 100 0 L 0 0 0 100"
fill="none"
stroke="black"
opacity="0.5"
stroke-width="1"
stroke-dasharray="3 1"
></path>
</pattern>
</defs>
<rect width="100%" height="100%" fill="url(#grid10)" />
<g v-if="selectedResult != null" :transform="getTransform()">
  <image :width="selectedResult.width_mm " :height="selectedResult.height_mm" :href="selectedResult.thumbnail_path"/>
  <polygon :points="resultCoordinates" class="op-svg-search-result" @click="redirectToProject()"/>
</g>
<template v-if="value.length > 0">
  <template v-if="isClosed">
    <polygon :points="coordinates" class="op-svg-search" marker-start="url(#dot)" marker-mid="url(#dot)"  marker-end="url(#dot)"/>
  </template>
  <template v-else>
    <polyline :points="coordinates" class="op-svg-search" marker-start="url(#dot)" marker-mid="url(#dot)"  marker-end="url(#dot)"/>
  </template>
</template>

<template v-if="vertexToMove != null">

  <circle
  :cx="vertexToMove.x"
  :cy="vertexToMove.y"
  r="4"
  fill="black"
  ></circle>

</template>
</svg>
</template>

<script>
import Point from "@/geometry/Point.js";
import Polygon from "@/geometry/Polygon.js";

export default {
  props: {
    value: Array,
    selectedResult: Object,
  },
  data: function () {
    return {
      resultCoordinates: null,
      isClosed: false,
      sensitivity: 20,
      vertexToMove: null,
    };
  },
  computed: {
    coordinates() {
      let coords = "";
      for (let p of this.value) {
        coords += p.x + "," + p.y + " ";
      }
      return coords;
    },
  },
  methods: {
    redirectToProject(){
      this.$router.push({
        name: 'ProjectView',
        params: {
          projectid: this.selectedResult.project_id,
        }
      });
    },
    getCoordinates(value) {
      let coords = "";
      for (let p of value) {
        coords += p.x + "," + p.y + " ";
      }
      return coords;
    },
    getPoints(coordinates) {
      let points = [];
      for (let arr of coordinates) {
        points.push(new Point(arr[0], arr[1]));
      }
      return points;
    },
    onMouseUp: function (e) {
      if (this.vertexToMove != null) {
        this.$emit("polygonClosed");
        this.vertexToMove = null;
      }
      return e;
    },
    onMouseDown: function (e) {
      if (this.value.length == 0) {
        this.isClosed = false;
      }
      let pt = new Point(e.offsetX, e.offsetY);
      if (this.isClosed == false) {
        if (this.value.length > 1) {
          let p0 = this.value[0];
          if (Polygon.distance(pt.x, pt.y, p0.x, p0.y) < this.sensitivity) {
            this.value.push(p0);
            this.isClosed = true;
            this.$emit("polygonClosed");
            return;
          }
        }
        this.value.push(pt);
      } else {
        // if is close to vertex, move vertex
        let closestVertex = Polygon.calculateClosestVertex(
          pt.x,
          pt.y,
          this.value
        );
        if (Point.distance(closestVertex.x, closestVertex.y, pt.x, pt.y) < this.sensitivity) {
          this.vertexToMove = closestVertex;
        }
      }
      return e;
    },
    onMouseMove: function (e) {
      if (this.vertexToMove != null) {
        this.vertexToMove.x = e.offsetX;
        this.vertexToMove.y = e.offsetY;
      }
      return e;
    },
    getTransform() {
      // scale to same bounds proportionally
      // translate center to center
      if (this.value.length > 0 && this.selectedResult.points.length > 0) {
        let bds1 = Polygon.boundingBox(this.value);
        let resultPoints = this.getPoints(this.selectedResult.points);

        this.resultCoordinates = this.getCoordinates(resultPoints);
        let bds2 = Polygon.boundingBox(resultPoints);

        let x1 = (bds1[0] + bds1[2]) * 0.5;
        let y1 = (bds1[1] + bds1[3]) * 0.5;

        let x2 = (bds2[0] + bds2[2]) * 0.5;
        let y2 = (bds2[1] + bds2[3]) * 0.5;

        let scaleX = (bds1[2] - bds1[0]) / (bds2[2] - bds2[0]);
        let scaleY = (bds1[3] - bds1[1]) / (bds2[3] - bds2[1]);
        let scale = Math.min(scaleX, scaleY);

        let tX = x1 - x2 * scale;
        let tY = y1 - y2 * scale;

        return "translate(" + tX + " " + tY + ")" + " scale(" + scale + ")";
      }
    },
  },
  created(){
  },
};
</script>
