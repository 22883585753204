<template>
  <svg
  v-if="imageURL != null"
  version="1.1"
  class="op-editor"
  :class="toolmodus && 'op-cursor-crosshair'"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  :viewBox="getViewBox"
  width="100%"
  height="100%"
  preserveAspectRatio="xMidYMid meet"
  ref="mySVG"
  v-on:mousedown="onMouseDownWorld"
  v-on:mousemove="onMouseMoveWorld"
  v-on:mouseup="onMouseUpWorld"
  >

  <defs>
    <marker
    id="dot"
    viewBox="0 0 10 10"
    refX="5"
    refY="5"
    markerWidth="5"
    markerHeight="5"
    >
    <circle cx="5" cy="5" r="3" class="op-svg-edit"/>
  </marker>

  <marker
  id="dot-scale"
  viewBox="0 0 10 10"
  refX="5"
  refY="5"
  markerWidth="5"
  markerHeight="5"
  >
  <circle cx="5" cy="5" r="3" class="op-svg-scale"/>
</marker>
<marker
id="dot-new"
viewBox="0 0 10 10"
refX="5"
refY="5"
markerWidth="5"
markerHeight="5"
>
<circle cx="5" cy="5" r="3" class="op-svg-new"/>
</marker>
<marker
id="dot-select"
viewBox="0 0 8 8"
refX="4"
refY="4"
markerWidth="4"
markerHeight="4"
>
<rect x="0" y="0" width="8" height="8" class="op-svg-select"/>
</marker>
</defs>

<image
ref="imageRef"
:href="imageURL"
:height="imageHeight"
:width="imageWidth"
preserveAspectRatio="xMinYMin meet"
/>

<!-- draw plain polygons -->
<template v-for="(polygon, index) in value">
  <template v-if="(polygon.points && polygon.points.length > 2)">
    <polygon v-if="!polygon.disabled && polygon.visible"
    :key="`poly-${index}`"
    :points="getCoordinates(polygon.points)"
    class="op-svg-polygon"
    />
  </template>
  <template v-else>
    <line v-if="!polygon.disabled && polygon.visible"
    :key="`line-${index}`"
    :x1="polygon.points[0].x"
    :y1="polygon.points[0].y"
    :x2="polygon.points[1].x"
    :y2="polygon.points[1].y"
    class="op-svg-line"
    />
  </template>
</template>

<!-- draw selected polygon -->
<template v-if="toolmodus==1">
  <template v-if="editing&&isLoggedIn">
    <template v-if="polygonActive != null && polygonActive.visible">
      <polygon :points="getCoordinates(polygonActive.points)" fill-opacity="0.2" class="op-svg-select"/>
    </template>
  </template>
</template>
<!-- draw editable polygon -->
<template v-if="toolmodus==0">
  <template v-if="editing&&isLoggedIn">
    <template v-if="polygonActive != null && polygonActive.visible">
      <polygon :points="getCoordinates(polygonActive.points)" fill-opacity="0.1" class="op-svg-edit" marker-start="url(#dot)" marker-mid="url(#dot)"  marker-end="url(#dot)"/>
    </template>
  </template>
  <template v-else>
    <template v-if="polygonActive != null && polygonActive.visible">
      <polygon :points="getCoordinates(polygonActive.points)" fill-opacity="0.1" class="op-svg-edit" />
    </template>
  </template>
</template>

<!-- draw selected polygon frame / obsolete -->
<!--
<template v-for="(polygon, indexP) in value">
<template v-if="polygon.selected == true && polygonsVisibility[indexP] == true">
<polyline :key="`polysel-${indexP}`"
:points="getPolygonSelectPoints(polygon.points)"
class="op-svg-select"
fill-opacity="0.1"
marker-start="url(#dot-select)" marker-mid="url(#dot-select)"  marker-end="url(#dot-select)"
/>
</template>
</template>
-->
<!-- draw scale line -->
<template v-if="scalePoints.length > 0 && scaleLineIsVisible">
  <template>
    <polyline :points="getCoordinates(scalePoints)" class="op-svg-scale" marker-start="url(#dot-scale)" marker-end="url(#dot-scale)"/>
  </template>
</template>

<!-- draw new polygon -->
<template v-if="points.length > 0">
  <template>
    <polyline :points="getCoordinates(points)" class="op-svg-new" marker-start="url(#dot-new)" marker-mid="url(#dot-new)"  marker-end="url(#dot-new)"/>
  </template>
</template>


<template v-if="vertexActive != null && (editing && isLoggedIn)">
  <circle
  :cx="vertexActive.x"
  :cy="vertexActive.y"
  :r="scale * 4"
  fill="black"
  ></circle>
</template>

<!-- SCALE LINE -->
<polyline :points="rulerCoords()" class="op-svg-ruler" fill="rgba(0, 0, 0, 0.75)"/>
<text class="op-noselect" :x="rulerTextCoords()[0]" :y="rulerTextCoords()[1]" :font-size="rulerFontSize">{{rulerSteps}}M</text>

</svg>
</template>

<script>
import SVGUtils from "@/geometry/SVGUtils.js";

import Matrix from "@/geometry/Matrix.js";
import Point from "@/geometry/Point.js";
import Polygon from "@/geometry/Polygon.js";

export default {

  props: {
    value: Array, // array of polygons as v-model
    toolmodus: Number, // 0 - Select/Edit, 1 - Draw, -1 - Scale
    imageURL: String,
    imageWidth: Number,
    imageHeight: Number,
    arraySelectIndex: Number,
    textFieldScale: String,
    editing: {
      type: Boolean,
      default: false
    },
    scaleLineIsVisible: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    numberOfTableElements: Number,
  },

  data: function () {
    return {
      svgElement: null,
      scale: 1,
      ctm: null,

      points: [], /* Current drawn polygon points */
      isClosed: false, /* Current drawn polygon is closed */

      scalePoints: [],
      scalePointsSaved: [],

      polygonActive: null,
      vertexActive: null,

      closestVertex: null,
      sensitivity: 10,
      pixelSensitivity: 10,

      rulerSteps: 4,
      rulerMeterLength: 1000,
    };
  },

  methods: {
    getPolygonBoundsHit(x, y) {
      let closestPoly = null;
      let index = -1;
      let closestDistance = Number.POSITIVE_INFINITY;
      for (let i = this.value.length - 1; i >= 0; i--) {
        if(this.value[i].visible){
          let cDist = Polygon.distanceToPoly(x, y, this.value[i].points);
          if (cDist < closestDistance) {
            closestDistance = cDist;
            closestPoly = this.value[i];
            index = i;
          }
        }
      }
      if (closestDistance < this.sensitivity) return [closestPoly, index];
      //else deselect all polygons
      this.emitArraySelectIndex(null);
      return null;
    },
    getPolygonHit(x, y) {
      for (let i = this.value.length - 1; i >= 0; i--) {
        if (Polygon.containsPointWinding(x, y, this.value[i].points)) {
          return this.value[i];
        }
      }
      return null;
    },
    saveScale(){
      if(this.scalePoints.length==2 && this.textFieldScale>0){
        console.log("save scale");
        this.scalePointsSaved = this.scalePoints;
        let sp = this.scalePoints;
        let currentLength = Point.distance(sp[0].x,sp[0].y,sp[1].x,sp[1].y);
        let newLength = this.textFieldScale;
        let scaling = newLength / currentLength;
        this.$emit("scaleAllByFactor", scaling);
        /* also scale scalePoints line */
        this.scalePoints[0].x *= scaling;
        this.scalePoints[0].y *= scaling;
        this.scalePoints[1].x *= scaling;
        this.scalePoints[1].y *= scaling;
        this.scalePointsSaved = this.scalePoints;
        this.updateScale();
      }
    },
    newScale(){
      this.scalePointsSaved = this.scalePoints;
      this.scalePoints = [];
    },
    revertScale(){
      //console.log("reverting scale...");
      this.scalePoints = this.scalePointsSaved;
    },
    clearPointsIfOpen(){
      if(this.points.length>0){
        if(!this.isClosed){
          console.log("clearing open points[]..." + this.points.length);
          this.points = [];
        }
      }
    },
    getPolygonSelectPoints(points){
      if (points.length==2){
        return [[points[0].x,points[0].y],[points[1].x,points[1].y]];
      }
      return Polygon.boundingBox8(points);
    },
    getCoordinates: function (points) {
      return SVGUtils.getCoordinates(points);
    },
    updateScale() {
      this.ctm = this.svgElement.getScreenCTM().inverse();
      this.scale = Matrix.decomposeMatrixScale(this.ctm);
      this.sensitivity = this.pixelSensitivity * this.scale;
    },

    getScaledPoint(e) {
      var pt = this.svgElement.createSVGPoint();
      pt.x = e.clientX;
      pt.y = e.clientY;
      pt = pt.matrixTransform(this.ctm);
      return new Point(pt.x, pt.y);
    },

    onMouseDownWorld(e) {
      let pt = this.getScaledPoint(e);
      //SELECT/EDIT MODE
      if (this.toolmodus == 0) {
        /* if there is a polygon edited (and visible) then */
        if (this.polygonActive != null && this.polygonActive.visible) {
          /* store the vertex that corresponds to mouse down */
          let cVertex = Polygon.calculateClosestVertex(pt.x,pt.y,this.polygonActive.points);
          if (Polygon.distance(pt.x, pt.y, cVertex.x, cVertex.y) > this.sensitivity) {
            cVertex = null;
          }
          if (cVertex != null) {
            this.vertexActive = cVertex;
            return;
          } else {
            this.vertexActive = null;
            this.polygonActive = null;
          }
        }
        /* if there is no active polygon then select one */
        //this.polygonActive = null;
        /* select hit */
        let polyBoundsHit = this.getPolygonBoundsHit(pt.x, pt.y);
        if(polyBoundsHit!=null){
          let polygon = polyBoundsHit[0];
          let index = polyBoundsHit[1];
          this.polygonActive = polygon;
          /* send selected index/mode to parent */
          this.emitArraySelectIndex(index);
        }
      }
      // DRAW MODE
      if(this.toolmodus == 1 && (this.editing && this.isLoggedIn)){
        this.polygonActive = null;
        if(this.points.length == 0){
          this.isClosed = false;
        }
        let point = new Point(pt.x,pt.y);
        if (this.isClosed == false) {
          if (this.points.length > 1) {
            let p0 = this.points[0];
            if (Polygon.distance(point.x, point.y, p0.x, p0.y) < this.sensitivity && this.points.length>2) {
              this.isClosed = true;

              let poly = new Polygon(this.points);
              this.$set(poly, 'pointsWereClosed', true); /* property needed to add the last (=first) point when saving */
              this.$set(poly, 'visible', true);
              this.$set(poly, 'tableIndex', this.numberOfTableElements);
              this.$set(poly, 'arrayIndex', this.value.length);

              this.$emit("addToPolygons",poly);

              this.$nextTick(() => {
                this.emitArraySelectIndex(this.value.length-1);
              });

              this.points = [];
              return;
            }
          }
          this.points.push(point);
        }else {
          // if is close to vertex, move vertex
          let closestVertex = Polygon.calculateClosestVertex(point.x,point.y,this.points);
          if (
            Point.distance(closestVertex.x, closestVertex.y, point.x, point.y) < this.sensitivity) {
              this.vertexToMove = closestVertex;
            } else {
              this.points.length = 0;
              this.points.push(point);
              this.isClosed = false;
              this.vertexToMove = null;
            }
          }
        }
        // SCALE MODE
        if(this.toolmodus == -1 ){
          let point = new Point(pt.x,pt.y);

          if(this.scalePoints.length<2){
            this.scalePoints.push(point);

            if(this.scalePoints.length==2){
              let newTextFieldValue = Point.distance(this.scalePoints[0].x,this.scalePoints[0].y,this.scalePoints[1].x,this.scalePoints[1].y);
              newTextFieldValue = Math.round(newTextFieldValue).toString();
              this.$emit('reopenDialogScale', newTextFieldValue);
            }
          }else{
            this.scalePoints = [];
            this.scalePoints.push(point);
          }
        }
      },

      onMouseMoveWorld(e) {
        if (this.editing && this.isLoggedIn){
          let pt = this.getScaledPoint(e); // mouse position point

          if (this.vertexActive != null) {
            this.vertexActive.x = pt.x;
            this.vertexActive.y = pt.y;
          }
          this.closestVertex = null;

          if (this.points.length > 2) {
            let p0 = this.points[0];
            if (Polygon.distance(pt.x, pt.y, p0.x, p0.y) < this.sensitivity) {
              this.closestVertex = p0;
            }
          }
          //return pt;
        }
      },
      onMouseUpWorld(e) {
        this.vertexActive = null;
        return e;
      },
      emitArraySelectIndex(ind){
        this.$emit('arraySelectIndex', ind);
        console.log("Emited arraySelectIndex " + ind + " from SVG to parent.");
      },
      svgPoint(element, x, y) {
        let pt = this.svgElement.createSVGPoint();
        pt.x = x;
        pt.y = y;
        return pt.matrixTransform( element.getScreenCTM().inverse() );
      },
      rulerCoords(){
        let coords = "";
        if(this.svgElement){
          let start = this.rulerStartingPoint();
          this.rulerSteps = this.getNumberOfRulerSteps();
          let rulerThickness = this.scale * 5.0;
          let x0 = start[0] - (this.rulerMeterLength * this.rulerSteps)/2.0;
          let y0 = start[1] - rulerThickness;

          coords += (x0 +"," + y0 + " ");
          for(let i=0;i<this.rulerSteps;i++){
            let y;
            if(i%2==0){
              y = y0-rulerThickness;
            }else{
              y = y0+rulerThickness;
            }
            coords += ( (x0+i*this.rulerMeterLength) + "," + y + " " + (x0 +(i+1)*this.rulerMeterLength) + "," + y + " " );
          }
          coords += (x0+this.rulerSteps*this.rulerMeterLength + "," + y0 + " ");
          coords += (x0 + "," + y0 + " ");
        }
        return coords
      },
      getNumberOfRulerSteps(){
        let num =  parseInt((this.imageWidth/3.0)/this.rulerMeterLength);
        if(num>6 && num<=10){
          num = Math.ceil(num/2)*2;
        }
        else if(num>10){
          num = Math.ceil(num/5)*5;
        }
        return num;
      },
      rulerStartingPoint(){
        let tc = [];
        if(this.svgElement){
          let point =  this.svgElement.createSVGPoint();
          let boundingRect = this.svgElement.getBoundingClientRect();
          point.x = (boundingRect.left + boundingRect.width/2.0);
          point.y = boundingRect.bottom - 19;
          let svgP = point.matrixTransform( this.svgElement.getScreenCTM().inverse());
          tc = [svgP.x,svgP.y];
        }
        return tc;
      },
      rulerTextCoords(){
        let tc = [];
        if(this.svgElement){
          let coords = this.rulerStartingPoint();
          tc = [coords[0] + (this.rulerSteps * this.rulerMeterLength / 2.0)+this.scale*5.0, coords[1]];
        }
        return tc;
      },
    },

    watch: {
      value: {
        deep: true,
        handler() {
          this.updateScale();
        },
      },
      arraySelectIndex: function(){
        if(this.arraySelectIndex==-1){
          this.polygonActive = null;
        }else{
          this.polygonActive = this.value[this.arraySelectIndex];
        }
      },
    },

    computed: {
      getViewBox: function () {
        return SVGUtils.getViewBox(
          this.imageWidth,
          this.imageHeight*(110/100)
        );
      },
      rulerFontSize(){
        return (this.scale * 0.75)+"rem";
      },
    },

    mounted() {
      this.svgElement = this.$refs["mySVG"];
      this.updateScale();
      window.addEventListener('resize', this.updateScale);
    },

    updated() {
      this.svgElement = this.$refs["mySVG"];
      this.updateScale();
    },
  };
  </script>
