<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="10">
        <v-card class="about" flat>
          <v-card-title class="text-h8">
            
          </v-card-title>
          <v-card-text class="about-text">
            <p>
              <b>OPEN PLANS</b> is a collaborative platform that offers intuitive access to an expanding
              database of semantically labelled floor plans, designed to enhance architectural research and
              design possibilities using machine learning and data-driven methods. The platform is available
              for use by scholars worldwide. More information on how to interact with the platform can be found here:
            </p>
            <p class="op-about-link">
              <a href="https://github.com/dbt-ethz/open-plans/blob/master/API.md" target="_blank">1) API Documentation</a>
            </p>
            <p class="op-about-link">
              <a href="https://colab.research.google.com/drive/1eNPpkEUVdtoOd1ZTwVlwKXU6mzf8HTWT?usp=sharing" target="_blank">2) API Google Colab Tutorial</a>
            </p>
            <p>
              This project was made possible by funding from Innovedum, which supports innovative teaching initiatives
              at ETH Zurich. The Chair of Digital Building Technologies was responsible for developing the platform.
              Open Plans is intended solely for academic purposes. If you believe that your copyright has been infringed
              upon by any content hosted on our platform, please notify us immediately by sending an email to <i>plans@dbt-ethz.ch</i>.
              We are committed to promptly addressing any copyright concerns and will take appropriate action to rectify any
              infringement.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card class="team" flat>
          <v-card-title class="text-h8">
            <b>Team</b>
          </v-card-title>
          <v-card-text class="team-text">
            <p>
              Angela Yoo<br>
              Simon Griffioen<br>
              Benjamin Dillenburger<br>
            </p>
            <p><u>Past Contributors:</u><br>
              Demetris Shammas<br>
              Yuta Akizuki<br>
              Mathias Bernhard<br>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card class="contact" flat>
          <v-card-title class="text-h8">
            <b>Contact</b>
          </v-card-title>
          <v-card-text class="contact-text">
            <p>
              If you are a researcher or developer and interested in integrating with the Open Plans database or
              contribute to new functionalities, please reach out to us at <i>plans@dbt-ethz.ch</i>
            </p>
            <p>
              Digital Building Technologies<br>
              Prof. Benjamin Dillenburger<br>
              ETH Zurich / Building HIB / Floor E / Room 23<br>
              Stefano-Franscini Platz 1 / CH-8093 Zurich<br>
              <a href="www.dbt.arch.ethz.ch" target="_blank">www.dbt.arch.ethz.ch</a><br>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="footer" flat>
      <v-card-text>
        <p>

        </p>
        <p>
          © {{ new Date().getFullYear() }} Digital Building Technologies
          <a href="https://dbt.arch.ethz.ch/" target="_blank">dbt.arch.ethz.ch</a>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style scoped>
.about,
.team,
.contact,
.footer {
  border: none;
  margin-top: 50px;
}

.about-text {
  font-size: 14px; /* Adjust the font size as needed */
}

.team-text {
  font-size: 14px; /* Adjust the font size as needed */
}

.contact-text {
  font-size: 14px; /* Adjust the font size as needed */
}

.op-about-link {
  font-size: 18px;
  text-align: left;
}

.footer {
  margin-top: 250px;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
</style>

<script>
export default {
  name: "About",
}
</script>
