<template>
  <div>
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
      height="3"
    ></v-progress-linear>
    <PlanEditor v-on:editPlan="editPlan" v-on:returnToProject="returnToProject" v-on:reloadPlanView="reloadPlanView" :editing="editing" :isLoggedIn="isLoggedIn" :userEditRights="userEditRights" :plan="plan" v-else />
  </div>
</template>

<script>

import PlanEditor from "@/components/PlanEditor.vue";
import { fetchPlan } from "@/api/plan";
import { fetchProject } from "@/api/project";

export default {
  name: "PlanView",
  components: {
    PlanEditor
  },
  data() {
    return {
      plan: null,
      loading: true,
      router: this.$route.params.planid,
      editing: false,
      pseudoLogin: false,
      createdBy: 0,
    };
  },
  computed:{
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userHasRole: function () {
      return this.$store.getters.userHasRole;
    },
    userHasId: function () {
      return this.$store.getters.userHasId;
    },
    userEditRights: function () {
      return this.userHasId === this.createdBy;
    },
  },
  methods: {
    async readDataFromAPI() {
      this.plan = await fetchPlan(this.router);
      let project = await fetchProject(this.plan.project_id);
      this.createdBy = project.created_by;
      if (this.plan != null) {
        /* if plan has no polygons yet this key does not exist so add it to the dict */
        if (!Object.prototype.hasOwnProperty.call(this.plan, 'polygons')) {
          this.plan.polygons = [];
        }
        this.loading = false;
      }
    },
    editPlan(){
      this.editing = true;
    },
    reloadPlanView(){
      this.$router.go();
    },
    returnToProject(){
      /* redirectToProject */
      this.$router.push({
        name: 'ProjectView',
        params: {
          projectid: this.plan.project_id,
        }
      });
    },
  },
  created() {
    this.readDataFromAPI();
    if (this.$route.query.editing) {
      this.editing = true;
    }
  },
};
</script>
