<template>
  <v-card fluid flat>
    <v-card flat>

  <v-data-table
  color="red"

  :pageCount="numberOfPages"
  :headers="getHeaders()"
  :search="search"
  :items="projects"
  :items-per-page="25"
  :options.sync="options"
  :server-items-length="totalPassengers"
  :loading="loading"
  :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
  class="op-projects-table op-cursor-pointer"
  @click:row="handleClick"
  :disable-sort="$isMobile()"
  >
  <template v-slot:top> </template>
<template v-slot:[`item.actions`]="{ item }">
  <v-icon small @click.stop="deleteItem(item)" v-if="userHasId === item.created_by || userHasRole === 'administrator'" class='op-project-table-delete'> mdi-window-close </v-icon>
</template>
<template v-slot:[`item.id`]="{ item }">
  {{ item.id }}
</template>
<template v-slot:[`item.logo`]="{ item }">
  <img
  v-for="(plan, index) in item.plans"
  :key="index"
  :src="plan.thumbnail_path"
  :style="imgWidth"
  class='op-project-table-img'
  />
</template>

<template v-slot:[`item.description`]="{ item }">
      {{ truncateDescription(item.description) }}
    </template>

</v-data-table>

</v-card>

<v-dialog v-model="dialogDelete" max-width="650px">
  <v-card>
    <v-card-title  class="text-center justify-center">Are you sure you want to permanently delete this project?<br> This action is irreversible!</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="op-card-actions" text @click="closeDelete">Cancel</v-btn>
      <v-btn class="op-card-actions" color="red" text @click="deleteItemConfirm">Delete</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

</v-card>

</template>

<script>
import { fetchRecentProjects } from "@/api/project";
import { fetchProjectsAccount } from "@/api/project";
import { deleteProject } from "@/api/project";
import { countProjects } from "@/api/project";
import { filterFetchProjects } from "@/api/project";

export default {
  components: {
  },
  name: "RecentProjects",
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    userHasRole: {
      type: String,
      default: '',
    },
    userHasId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: '',
      dialog: false,
      dialogDelete: false,
      totalPassengers: 0,
      numberOfPages: 0,
      projects: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 25,
      },
      breadcrumbs: [
        {
          text: "Projects",
          disabled: false,
          to: {
            name: "ProjectsView",
            query: { type: "browse" },
          },
        },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        id: 0
      },
      defaultItem: {
        name: "",
        id: 0
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.readDataFromAPI();
      },
    },
    deep: true,
  },
  methods: {
    handleClick(value) {
        this.$router.push({
          name: 'ProjectView',
          params: {
            projectid: value.id,
          }
        });
    },
    getHeaders(){
      var headers = [
        { text: "ID", value: "id" , sortable: false, width:"75px" },
        { text: "Plans", value: "logo", sortable: false, width:"40%" },
        { text: "Project Name", value: "name" , sortable: false},
        { text: "Architects", value: "architects" , sortable: false},
      ];
      if(!this.$isMobile()){
        headers.push({ text: "Description", value: "description", sortable: false });
      }
      if(this.isLoggedIn && !this.$isMobile()){
        headers.push({ text: "Actions", value: "actions" , sortable: false});
      }
      return headers;
    },
    truncateDescription(description) {
      if (description === null) {
        return ""; // or handle the null case as per your requirement
      }

      const words = description.split(' ');
      if (words.length > 100) {
        return words.slice(0, 100).join(' ') + '...';
      }
      return description;
    },
    /*
    editItem(item) {
    this.editedIndex = this.desserts.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  },
  */
  deleteItem(item) {
    //this.editedIndex = this.desserts.indexOf(item);
    this.editedItem = Object.assign({}, item);
    console.log("delete item " + item);
    this.dialogDelete = true;
  },
  closeDelete() {
    this.dialogDelete = false;
    this.reloadProjetsView();
  },
  async deleteItemConfirm() {
    await this.TryToDeleteProject();
    this.editedItem = Object.assign({}, this.defaultItem);
    this.closeDelete();
  },
  async readDataFromAPI() {
    this.loading = true;
    const { page, itemsPerPage } = this.options;

    let pageNumber = page - 1;

    if (this.$route.query.type === 'browse') {

      const projects = await fetchRecentProjects(itemsPerPage, pageNumber);
      this.loading = false;
      this.projects = projects;

    } else if (this.$route.query.type === 'myprojects') {

      const projects = await fetchProjectsAccount(this.userHasId, itemsPerPage, pageNumber);
      this.loading = false;
      this.projects = projects;
   
    } else if (this.$route.query.type.startsWith('search')) { // search option

      const projects = await filterFetchProjects(this.$route.query.type, itemsPerPage, pageNumber);
      this.loading = false;
      this.projects = projects;
      this.totalPassengers = projects.length;

    }
  },
  async TryToDeleteProject() {
    const data = { project_id: this.editedItem.id };
    const response = await deleteProject(data);
    return response
  },
  reloadProjetsView() {
    this.$emit('reloadProjectsView', this.$route.fullPath);
  },
  async countDataFromAPI() {
    this.loading = true;
    const response = await countProjects();
    if(response.succeeded) {
      this.totalPassengers = response.count;
      this.numberOfPages = Math.ceil(response.count / this.options.itemsPerPage);
      this.loading = false;
    }
  },
},
mounted() {
  this.readDataFromAPI();
},
created() {
  this.countDataFromAPI();
},
computed: {
  imgWidth () {
    let w;
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      w= 100
      break;
      case 'sm':
      w= 100
      break;
      case 'md':
      w= 50
      break;
      default:
      w= 50
      break;
    }
    return "width: " + w + "%";
  },
},
};
</script>
