<template>
  <v-card flat tile>
    <v-card-text class="op-editor-table">
      <v-data-table
      dense
      :items="valueConditional"
      item-key="id"
      hide-default-footer
      fixed-header
      hide-default-header
      :disable-pagination="true"
      id="polygons-table"
      class='op-noselect'
      >

      <template v-slot:header>
        <thead>
          <th class="text-center op-editor-icon-th" v-if="editing && isLoggedIn"><v-icon medium>mdi-delete-forever</v-icon></th>
          <th class="op-editor-icon-th op-cursor-pointer" @click="togglePolygonsVisibility"><v-icon>{{globalVisibility ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}}</v-icon></th>
          <th>ID</th>
          <th>Tags</th>
          <th>Area sqm</th>
          <th v-if="!$isMobile()">Perimeter m</th>
        <!--
          <th>Table Index</th>
          <th>Array Index</th>
        -->
        </thead>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item) in items" :key="item.name" @click="select(item.arrayIndex)">
            <td v-if="editing && isLoggedIn">
              <v-btn icon>
                <v-icon small @click="deleteItem()" color="black">mdi-window-close</v-icon>
              </v-btn>
            </td>

            <td>
              <v-simple-checkbox
              v-model="item.visible"
              class="op-editor-icon"
              color="black"
              on-icon ="mdi-eye-outline"
              off-icon ="mdi-eye-off-outline"
              :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td>{{ item.id }}</td>

            <td>

              <v-select
              deletable-chips
              v-model="item.tags"
              class='op-dropdown'
              dense
              :items="polygonClassList"
              label=""
              multiple
              item-color='black'
              :disabled="!editing || !isLoggedIn"
              >

              <template v-slot:selection="{ item }">
                <v-chip
                small
                color="black"
                text-color="white"
                >
              <span>{{ item }}</span>
            </v-chip>
      </template>
    </v-select>
  </td>

  <td>{{ getPolygonArea(item.points) }}</td>
  <td v-if="!$isMobile()">{{ getPolygonPerimeter(item.points)}} </td>
<!--
  <td>{{ item.tableIndex }} </td>
  <td>{{ item.arrayIndex }} </td>
-->
</tr>
</tbody>

<v-dialog hide-overlay v-model="dialogDelete" max-width="500px">
  <v-card>
    <v-card-title class="justify-center">Are you sure you want to delete this polygon?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="op-card-actions" text @click="closeDelete">Cancel</v-btn>
      <v-btn class="op-card-actions" color="red" text @click="deleteItemConfirm">Delete</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

</template>

</v-data-table>
</v-card-text>
</v-card>

</template>


<script>
import Polygon from "@/geometry/Polygon.js";

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },

    arraySelectIndex: Number,
    toolmodus: Number,
  },

  data: () => ({
    dialogDelete: false,
    globalVisibility: true,
    allInvisible: false,

    pseudoClasslist: [
      "building-outline",
      "balcony",
      "bath",
      "chimney",
      "closet",
      "corridor",
      "dining",
      "elevator",
      "foyer",
      "garden",
      "garage",
      "kitchen",
      "living",
      "room",
      "stair",
      "sleeping",
      "terrace",
      "wc",
    ],
  }),
  watch: {

    value: {
      handler: function (val) {
        this.$emit("input", val);
        this.recalculateGlobalVisibility();
      },
      deep: true
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    arraySelectIndex: function(){
      this.updateRowClasses(this.tableIndexFromArrayIndex(this.arraySelectIndex));
    },
    toolmodus: function(){
      this.updateRowClasses(this.tableIndexFromArrayIndex(this.arraySelectIndex));
    },
  },
  methods: {
    tableIndexFromArrayIndex(arrayIndex){
      let tableSelectIndex = -1;
      if(this.value[arrayIndex]){
        tableSelectIndex = this.value[arrayIndex].tableIndex;
      }
      return tableSelectIndex;
    },
    recalculateGlobalVisibility(){
      let count = 0;
      for(let i=0;i<this.value.length;i++){
        if(this.value[i].visible==false){
          count++;
        }else{
          this.globalVisibility=true;
          break;
        }
      }
      if(count==this.value.length){
        this.globalVisibility=false;
      }
    },
    togglePolygonsVisibility(){
      this.$emit("togglePolygonsVisibility");
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    rowRemoveClassesAndAddNew(index, className){
      var table = document.getElementById('polygons-table');
      var rows = table.querySelectorAll('tr');
      rows.forEach(item => {
        item.classList.remove('op-table-highlight');
        item.classList.remove('op-table-highlight-edit');
      });
      if(rows[index] && index!=-1){
        rows[index].classList.add(className);
      }
    },
    updateRowClasses(tableIndex){
      switch(this.toolmodus){
        case 0: //Select / Edit Mode
        this.rowRemoveClassesAndAddNew(tableIndex, 'op-table-highlight-edit');
        break;
        case 1: //New Polygon Mode
        this.rowRemoveClassesAndAddNew(tableIndex, 'op-table-highlight');
        break;
        default:
        break;
      }
    },
    select(arrayIndex){
      this.updateRowClasses(this.tableIndexFromArrayIndex(arrayIndex));
      this.$emit('arraySelectIndex', arrayIndex);
      console.log("Emited arraySelectIndex " + arrayIndex + " from table to parent.");
    },
    deleteItemConfirm(){
      this.$emit('deleteIndex', this.arraySelectIndex);
      this.closeDelete();
    },
    getPolygonArea(points){
      return (1e-6*Polygon.area(points)).toFixed(1);
    },
    getPolygonPerimeter(points){
      return (1e-3*Polygon.polygonPerimeter(points)).toFixed(1);
    },
},
computed: {
  polygonClassList() {
    //return this.$store.state.classList;
    return this.pseudoClasslist;
  },
  valueConditional(){
    let v = [];
    for(let i=0;i<this.value.length;i++){
      if(!this.value[i].disabled){
        v.push(this.value[i]);
      }
    }
    return v;
  },
},
};
</script>
