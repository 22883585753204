import Point from './Point.js'

export default class Polygon {

  constructor(points = []) {
    this.id="";
    this.plan_id="";
    this.points=points;
    this.tags=[];
  }

  static boundingBox(points) {
    let x1 = Number.POSITIVE_INFINITY;
    let y1 = Number.POSITIVE_INFINITY;
    let x2 = Number.NEGATIVE_INFINITY;
    let y2 = Number.NEGATIVE_INFINITY;
    for (const point of points) {
      x1 = Math.min(point.x, x1);
      y1 = Math.min(point.y, y1);
      x2 = Math.max(point.x, x2);
      y2 = Math.max(point.y, y2);
    }
    return [x1, y1, x2, y2];
  }

  static boundingBox8(points) {
    let x1 = Number.POSITIVE_INFINITY;
    let y1 = Number.POSITIVE_INFINITY;
    let x2 = Number.NEGATIVE_INFINITY;
    let y2 = Number.NEGATIVE_INFINITY;
    for (const point of points) {
      x1 = Math.min(point.x, x1);
      y1 = Math.min(point.y, y1);
      x2 = Math.max(point.x, x2);
      y2 = Math.max(point.y, y2);
    }
    let dX=(x2-x1)*0.5;
    let dY=(y2-y1)*0.5;
    return [[x1, y1],[x1+dX, y1],[x2, y1],[x2, y1+dY],[x2, y2],[x2-dX, y2],[x1, y2],[x1, y2-dY]];
  }


  static distance(x1, y1, x2, y2) {
    let dX = x2 - x1;
    let dY = y2 - y1;
    return Math.sqrt(dX * dX + dY * dY);
  }

  static area(points){
    if(points.length<=2){
      return 0;
    }
    let pts = [...points];
    if(!Polygon.isClosed(pts)){
      pts.push(pts[0]);
    }
    /*
    shoelace formula - Returns the area of the polygon whose vertices are given by thesequence points.
    */
    let area = 0;
    let q = pts[pts.length - 1];
    for(let i=0;i<pts.length;i++){
      let p = pts[i];
      area += p.x * q.y - p.y * q.x;
      q = p;
    }
    return Math.abs(area/2);
  }

  static polylineLength(pts){
    let p = 0;
    for(let i=0;i<pts.length-1;i++){
      let a = Point.distance(pts[i].x, pts[i].y, pts[i+1].x, pts[i+1].y);
      p +=a;
    }
    return p;
  }

  static polygonPerimeter(points){
    let pts = [...points];
    if(!Polygon.isClosed(pts)){
      pts.push(pts[0]);
    }
    let p = 0;
    for(let i=0;i<pts.length;i++){
      let a = Point.distance(pts[i].x, pts[i].y, pts[(i+1)%pts.length].x, pts[(i+1)%pts.length].y);
      p +=a;
    }
    return p;
  }

  static isClosed(points){
    if(points[0].x==points[points.length - 1].x && points[0].y==points[points.length - 1].y){
      return true;
    }return false;
  }

  static calculateClosestVertex(x, y, positions) {
    let minD = Number.POSITIVE_INFINITY;
    let closestVertex = null;
    for (let i = 0; i < positions.length; i++) {
      let p0 = positions[i];
      let cD = this.distance(x, y, p0.x, p0.y);
      if (cD < minD) {
        minD = cD;
        closestVertex = p0;
      }
    }
    return closestVertex;
  }

  static ccw(ax, ay, bx, by, cx, cy) {
    let area2 = (bx - ax) * (cy - ay) - (cx - ax) * (by - ay);
    if (area2 < 0) return -1;
    else if (area2 > 0) return +1;
    else return 0;
  }

  static distanceToSegment(x1, y1, x2, y2, pX, pY) {
    return Math.sqrt(this.distanceToSegmentSq(x1, y1, x2, y2, pX, pY));
  }

  static distanceToSegmentSq(x1, y1, x2, y2, pX, pY) {
    let dX = x2 - x1;
    let dY = y2 - y1;
    let vX,
    vY = 0;
    let t = ((pX - x1) * dX + (pY - y1) * dY) / (dX * dX + dY * dY);
    if (t <= 0) {
      vX = x1 - pX;
      vY = y1 - pY;
    } else if (t >= 1) {
      vX = x2 - pX;
      vY = y2 - pY;
    } else {
      vX = x1 + t * dX - pX;
      vY = y1 + t * dY - pY;
    }
    return vX * vX + vY * vY;
  }

  static distanceToPoly(x, y, points) {
    if (points.length > 2) {
      let minD = Number.POSITIVE_INFINITY;
      let p0 = points[points.length - 1];
      for (let i = 0; i < points.length; i++) {
        let p1 = points[i];
        let cD = this.distanceToSegment(
          p0.x,
          p0.y,
          p1.x,
          p1.y,
          x,
          y
        );
        if (!Number.isNaN(cD)) {
          minD = Math.min(cD, minD);
        }
        p0 = p1;
      }
      return minD;
    }

  }
  static containsPointWinding(x, y, coordinates) {
    let winding = 0;
    let p0 = coordinates[coordinates.length - 1];
    for (let i = 0; i < coordinates.length; i++) {
      let p1 = coordinates[i];
      let ccw = this.ccw(p0.x, p0.y, p1.x, p1.y, x, y);
      if (p1.y > y && y >= p0.y)
      if (ccw == +1)
      // upward crossing
      winding++;
      if (p1.y <= y && y < p0.y)
      if (ccw == -1)
      // downward crossing
      winding--;
      p0 = p1;
    }
    return winding != 0;
  }
}
