var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"fluid":"","flat":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"op-projects-table op-cursor-pointer",attrs:{"color":"red","pageCount":_vm.numberOfPages,"headers":_vm.getHeaders(),"search":_vm.search,"items":_vm.projects,"items-per-page":25,"options":_vm.options,"server-items-length":_vm.totalPassengers,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [25, 50, 100] },"disable-sort":_vm.$isMobile()},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userHasId === item.created_by || _vm.userHasRole === 'administrator')?_c('v-icon',{staticClass:"op-project-table-delete",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-window-close ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return _vm._l((item.plans),function(plan,index){return _c('img',{key:index,staticClass:"op-project-table-img",style:(_vm.imgWidth),attrs:{"src":plan.thumbnail_path}})})}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncateDescription(item.description))+" ")]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center justify-center"},[_vm._v("Are you sure you want to permanently delete this project?"),_c('br'),_vm._v(" This action is irreversible!")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"op-card-actions",attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"op-card-actions",attrs:{"color":"red","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Delete")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }