<template>

  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header class='pt-0' v-slot="{ open }" @open="isPanelOpen = open">
        <div class="header-content">
          <span class="filter-label">Filter options:</span>
        <!-- <v-row no-gutters>
          <v-col  class="op-gray-small" cols="12" xs="12" sm="3">
            Filter Options 
          </v-col>
          <v-col v-if="!isPanelOpen || shouldExpandFilters" cols="3 d-none d-sm-flex" class="op-gray-small">{{labels[0]}}: {{ printYear() }}</v-col>
          <v-col v-if="!isPanelOpen || shouldExpandFilters" cols="3 d-none d-sm-flex" class="op-gray-small">{{labels[1]}}: {{ printFloors() }}</v-col>
          <v-col v-if="!isPanelOpen || shouldExpandFilters" cols="3 d-none d-sm-flex" class="op-gray-small">{{labels[2]}}: {{ printArea() }}</v-col>
        </v-row> -->
 
      </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <v-row justify="space-around" no-gutters>
          <v-col cols="12" sm="3">
            <v-card-text class="py-0 text-center">
              {{labels[0]}}
            </v-card-text>
            <v-range-slider
            v-model="yearRange"
            :max="yearMax"
            :min="yearMin"
            hide-details
            class="align-center"
            color="black"
            track-color="gray"
            @change="handleSliderChange"
            >
            <template v-slot:prepend>
              <v-text-field
              :value="yearRange[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 50px"
              
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
              :value="yearRange[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 50px"
              
              ></v-text-field>
            </template>
          </v-range-slider>
        </v-col>

        <v-col cols="12" sm="3">
          <v-card-text class="py-0 text-center">
            {{labels[1]}}
          </v-card-text>
          <v-range-slider
          v-model="floorRange"
          :max="floorMax"
          :min="floorMin"
          hide-details
          class="align-center"
          color="black"
          track-color="gray"
          @change="handleSliderChange"
          >
          <template v-slot:prepend>
            <v-text-field
            :value="floorRange[0]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 50px"
            
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
            :value="floorRange[1]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 50px"
            
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>

      <v-col cols="12" sm="3">
        <v-card-text class="py-0 text-center">
          {{labels[2]}}
        </v-card-text>
        <v-range-slider
        v-model="areaRange"
        :max="areaMax"
        :min="areaMin"
        hide-details
        class="align-center"
        color="black"
        track-color="gray"
        @change="handleSliderChange"
        >
        <template v-slot:prepend>
          <v-text-field
          :value="areaRange[0]"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 50px"
          
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
          :value="areaRange[1]"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 50px"
          
          ></v-text-field>
        </template>
      </v-range-slider>
    </v-col>
  </v-row>
</v-expansion-panel-content>
</v-expansion-panel>

</v-expansion-panels>
</template>


<script>

export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    shouldExpandFilters: {
      get() {
        return (
          this.yearRange[0] !== this.yearMin ||
          this.yearRange[1] !== this.yearMax ||
          this.floorRange[0] !== this.floorMin ||
          this.floorRange[1] !== this.floorMax ||
          this.areaRange[0] !== this.areaMin ||
          this.areaRange[1] !== this.areaMax
        );
      },
    },
  },
  data: function () {
    return {
      isPanelOpen: false,
      yearMin: 1500,
      yearMax: this.getCurrentYear(),
      yearRange: [1500, this.getCurrentYear()],

      floorMin: 0,
      floorMax: 100,
      floorRange: [0, 100],

      areaMin: 0,
      areaMax: 1000,
      areaRange: [0, 1000],

      labels:['Year of Completion', 'Number of Floors', 'Floor Area (sqm)'],
    }
  },
  watch: {
  },
  methods:{
    extractSearchValues() {
      if (this.$route.query.type && this.$route.query.type.startsWith('search=')) {
        const searchParamsString = decodeURIComponent(this.$route.query.type);
        const searchParams = searchParamsString.split('&');
        const searchValues = {};

        searchParams.forEach(param => {
          const [key, value] = param.split('=');
          searchValues[key] = value;
        });

        // Use the extracted search values to update your component's data or apply the filter values

        // Example: Update the yearRange with extracted values
        if (searchValues.year_from && searchValues.year_to) {
          this.yearRange = [parseInt(searchValues.year_from), parseInt(searchValues.year_to)];
        }

        // Example: Update the areaRange with extracted values
        if (searchValues.area_from && searchValues.area_to) {
          this.areaRange = [parseInt(searchValues.area_from), parseInt(searchValues.area_to)];
        }

        // Example: Update the floorRange with extracted values
        if (searchValues.floors_from && searchValues.floors_to) {
          this.floorRange = [parseInt(searchValues.floors_from), parseInt(searchValues.floors_to)];
        }
      }
    },
    handleSliderChange() {
      clearTimeout(this.rangeChangeTimeout);
      this.rangeChangeTimeout = setTimeout(this.applyFilters, 300);
    },
    applyFilters(){
      // const minValue = this.yearRange[0];
      // const maxValue = this.yearRange[1];

      // const minValuef = this.floorRange[0];
      // const maxValuef = this.floorRange[1];

      // const minValuea = this.areaRange[0];
      // const maxValuea = this.areaRange[1];

      // console.log('Min Value:', minValue);
      // console.log('Max Value:', maxValue);

      // console.log('Min Value:', minValuef);
      // console.log('Max Value:', maxValuef);

      // console.log('Min Value:', minValuea);
      // console.log('Max Value:', maxValuea);
      
      const query = [];

      if (this.yearRange[0] !== this.yearMin || this.yearRange[1] !== this.yearMax) {
        query.push(`year_from=${this.yearRange[0]}`);
        query.push(`year_to=${this.yearRange[1]}`);
      }

      if (this.floorRange[0] !== this.floorMin || this.floorRange[1] !== this.floorMax) {
        query.push(`floors_from=${this.floorRange[0]}`);
        query.push(`floors_to=${this.floorRange[1]}`);
      }

      if (this.areaRange[0] !== this.areaMin || this.areaRange[1] !== this.areaMax) {
        query.push(`area_from=${this.areaRange[0]}`);
        query.push(`area_to=${this.areaRange[1]}`);
      }

      const queryString = query.join('&');

      this.$router.push({ path: '/projects', query: { type: 'search='+ '' + '&' + queryString}});
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    printYear(){
      if(this.yearMin==this.yearRange[0] && this.yearMax==this.yearRange[1]){return "all";}
      else{
        return this.yearRange[0] + " - " + this.yearRange[1];
      }
    },
    printFloors(){
      if(this.floorMin==this.floorRange[0] && this.floorMax==this.floorRange[1]){return "all";}
      return this.floorRange[0] + " - " + this.floorRange[1];
    },
    printArea(){
      if(this.areaMin==this.areaRange[0] && this.areaMax==this.areaRange[1]){return "all";}
      return this.areaRange[0] + " - " + this.areaRange[1];
    },
  },
  created () {
    this.extractSearchValues();
    console.log(this.shouldExpandFilters);
  },
  mounted () {
  },
};
</script>
